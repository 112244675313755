import { Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  imports: [CommonModule, IconComponent],
})
export class RatingStarsComponent {
  rating = input.required<number>();
  maxRating = input(5);
  stars = computed(() => {
    return Array(this.maxRating()).fill(0);
  });
}
